import React, { useState } from 'react'
import {
  Typography,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress
} from '@material-ui/core';
import { useRecoilValueLoadable, useRecoilCallback, useSetRecoilState } from 'recoil';

import { getSignerCreds, uploadSignerCreds, viewDocs, getSignerCredHistory } from 'src/Recoil/Selectors/Signer';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { GeneralModal } from '../Modal'
import { reloadCreds, refetchSignerCredHistory, fetchSignerDocument } from 'src/Recoil/Atoms/Signers';

import { useStyles } from './Styles/Credentials'

const rows = [
  {
    title: <>W-9: Print, Complete, Sign and Upload Below
      (<a href='https://www.irs.gov/pub/irs-pdf/fw9.pdf'
        target='_blank'
        rel='noopener noreferrer'
      >
        Click Here
      </a>  to download form)
    </>,
    docType: 'W9',
    hasDate: false,
  },
  {
    title: <>Privacy Policy: Print, Complete, Sign and Upload Below
      (<a
        href='/static/privacy_agreement.pdf'
        target='_blank'
        rel='noopener noreferrer'
      >
        Click Here
      </a>  to download )
    </>,
    docType: 'PP',
    hasDate: false,
  },
  {
    title: <>NSA Certification (<a
      href='https://www.nationalnotary.org/signing-agent/nsa'
      target='_blank'
      rel='noopener noreferrer'
    >
      Click Here</a> To Become Certified)
    </>,
    docType: 'NC',
    hasDate: true,
  },
  {
    title: <>Background Check</>,
    docType: 'BGCHKNON',
    hasDate: true,
  },
  {
    title: 'Driver License',
    docType: 'DL',
    hasDate: true,
  },
  {
    title: 'Notary Commissions',
    docType: 'NC',
    hasDate: true,
  },
  {
    title: 'Notary Bond (If Applicable)',
    docType: 'NB',
    hasDate: true,
  },
  {
    title: 'Notary Error and Omission Policy (If Available)',
    docType: 'NEOP',
    hasDate: true,
  },
  {
    title: 'Attorney State Bar License (If Attorney)',
    docType: 'ASBL',
    hasDate: true,
  },
  {
    title: 'Title Insurance Producer TIP (If Applicable)',
    docType: 'TIP',
    hasDate: true,
  },
];
const SignerCredentials = ((props: any) => {

  const setReload = useSetRecoilState(reloadCreds)
  const refresh = () => setReload(row => row + 1)
  const [loading, setLoading] = useState('')
  const [expireDate, setExpireDate] = useState({
    DL: null,
    NC: null,
    NB: null,
    NEOP: null,
    W9: null,
    PP: null,
    ASBL: null,
    TIP: null,
    BGCHK: null,
    BGCHKNON: null,
  })

  const [files, setFiles] = useState({
    DL: null,
    NC: null,
    NB: null,
    NEOP: null,
    W9: null,
    PP: null,
    ASBL: null,
    TIP: null,
    BGCHK: null,
    BGCHKNON: null,
  })
  const [uploadingFile, setUploadingFile] = useState(null)

  const signerDocsRequest: any = useRecoilValueLoadable(getSignerCreds({ id: props.id }))
  const signerDocs = signerDocsRequest?.contents?.result?.rows || []
  const isLoadingFiles = signerDocsRequest.state === 'loading'
  const refetchHistory = useSetRecoilState(refetchSignerCredHistory)
  const refetchDocument = useSetRecoilState(fetchSignerDocument)

  const [documents, setDocuments] = useState(undefined)
  const [historyLoading, setHistoryLoading] = useState(false)
  const classes = useStyles()

  const onUploadFile = useRecoilCallback(({ snapshot }) => async (docType: any) => {

    if (!files[docType]) {
      enqueueSnackbar(
        'Please select file',
        {
          variant: 'error'
        }
      )
    } else if (!expireDate[docType] && rows.find(row => row.docType === docType)?.hasDate) {
      enqueueSnackbar(
        'Please select date',
        {
          variant: 'error'
        }
      )
    } else {
      setUploadingFile(docType)
      const res = await snapshot.getPromise(uploadSignerCreds({
        doc_type: docType, expire_date: expireDate[docType], file: files[docType], id: props.id
      }));
      if (res.success) {
        setUploadingFile(null)
        setExpireDate({
          ...expireDate,
          [docType]: ''
        })
        setFiles({
          ...expireDate,
          [docType]: null
        })
        refresh()
        enqueueSnackbar(
          res?.result?.message || 'File Uploaded',
          {
            variant: 'success'
          }
        )
      } else {
        setUploadingFile(null)
        enqueueSnackbar(
          res?.result?.data?.message || 'Error occurred',
          {
            variant: 'error'
          }
        )
      }
    }
  });

  const onViewDocs = useRecoilCallback(({ snapshot }) => async (filename, loadingFile: string, docType) => {
    setLoading(loadingFile)
    setHistoryLoading(true)
    refetchDocument(n => n + 1)
    const res = await snapshot.getPromise(viewDocs({
      folder: `signers/${docType}`,
      filename,
      action: 'view/download'
    }));
    if (res.success) {
      const fileURL = URL.createObjectURL(res.result);
      setLoading('')
      window.open(fileURL);
    } else {
      enqueueSnackbar(
        res?.result?.data?.message || 'Error occurred',
        {
          variant: 'error'
        }
      )
    }
    setHistoryLoading(false)
  });


  const getSignerHistory = useRecoilCallback(({ snapshot }) => async (params: any) => {
    refetchHistory(n => n + 1)
    setHistoryLoading(true)

    const res = await snapshot.getPromise(getSignerCredHistory({ folder: `signers/${params.docType}`, signer_id: props.id }))

    if (res.success) {
      setDocuments({ rows: res.result.documents, docType: params.docType })
    }

    setHistoryLoading(false)

  })

  return (
    <SnackbarProvider>
      <>
        <GeneralModal
          title='History'
          open={documents}
          openModal={e => { setDocuments(undefined) }}
          noSubmit
        >
          {documents?.rows?.length ? documents.rows.map(document => {
            return (
              <Button
                className={classes.fileList}
                variant='outlined'
                fullWidth
                disabled={historyLoading}
                onClick={(e) => {
                  e.preventDefault()
                  onViewDocs(document.baseName, document.baseName, documents.docType)
                }}
              >
                {document.baseName}
              </Button>
            )
          })
            :
            <></>
          }
        </GeneralModal>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='body1' color='error'>
              Please scan and upload the required documents below.
            </Typography>

            {rows.map(row => (
              <Table
                className=''
                aria-label='simple table'
                style={{ marginTop: '30px' }}
                key={row.docType}
              >
                <TableHead>
                  <TableRow style={{ border: '1px solid #ccc' }}>
                    <TableCell style={{ borderRight: '1px solid #ccc' }}>
                      {row?.title}:
                    </TableCell>
                    {row.hasDate && (
                      <TableCell style={{ borderRight: '1px solid #ccc' }}>
                        Expire Date
                      </TableCell>
                    )}
                    <TableCell align='right'>
                      <Button variant='outlined' disabled={historyLoading} color='primary' onClick={() => { getSignerHistory(row) }}>History</Button>
                    </TableCell>
                    {/* <TableCell align='right'>Fat&nbsp;(g)</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoadingFiles && (
                    <TableRow>
                      <TableCell
                        component='td'
                        scope='row'
                        colSpan={2}
                        style={{ textAlign: 'center' }}
                      >
                        <CircularProgress size={30}></CircularProgress>
                      </TableCell>
                    </TableRow>)}
                  {!signerDocs.filter(d => d.doc_type === row.docType)?.length && (
                    <TableRow>
                      <TableCell
                        component='td'
                        scope='row'
                        colSpan={2}
                        style={{ borderRight: '1px solid #ccc' }}
                      >
                        No result found.
                      </TableCell>

                    </TableRow>
                  )}
                  {signerDocs.filter(d => d.doc_type === row.docType).map(doc => (
                    <TableRow style={{ border: '1px solid #ccc' }}>
                      <TableCell
                        component='td'
                        scope='row'
                        colSpan={row.hasDate ? 1 : 2}
                        style={{ borderRight: '1px solid #ccc' }}
                      >
                        <a href={`/${doc.doc_name}`} onClick={e => {
                          e.preventDefault();
                          onViewDocs(doc.doc_name,
                            row.docType.concat(doc.doc_name), row.docType)
                        }}>
                          {row.docType.concat(doc.doc_name) === loading ?
                            <CircularProgress size={25}></CircularProgress>
                            : doc.doc_name
                          }
                        </a>
                      </TableCell>
                      {row.hasDate && (
                        <TableCell
                          style={{
                            borderRight: '1px solid #ccc',
                            borderLeft: '1px solid #ccc'
                          }}
                        >
                          {doc.expire_date}
                        </TableCell>
                      )}
                      {row.hasDate && <TableCell align='right'></TableCell>}
                    </TableRow>
                  ))}
                </TableBody>
                <TableHead>
                  <TableRow style={{ border: '1px solid #ccc' }}>
                    <TableCell style={{ borderRight: '1px solid #ccc' }}>
                      {files[row.docType] ? <b>{files[row.docType]?.name}</b> : <input
                        type='file'
                        onChange={e => setFiles({
                          ...files,
                          [row.docType]: e.target.files[0]
                        })}
                      />}
                    </TableCell>
                    {row.hasDate && (
                      <TableCell style={{ borderRight: '1px solid #ccc' }}>
                        <input
                          type='date'
                          value={expireDate[row.docType]}
                          onChange={e => setExpireDate({
                            ...expireDate,
                            [row.docType]: e.target.value
                          })}
                        />
                      </TableCell>
                    )}
                    <TableCell align='left'>
                      <Button
                        variant='contained'
                        color='primary'
                        style={{ marginRight: '10px' }}
                        onClick={e => onUploadFile(row.docType)}
                        disabled={uploadingFile === row.docType}
                      >
                        {uploadingFile === row.docType &&
                          <> <CircularProgress size={25}></CircularProgress> &nbsp; </>
                        }
                        Save
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            ))}
          </Grid>
        </Grid>
      </>
    </SnackbarProvider>

  )
})
export { SignerCredentials }
