import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Typography,
  Box,
  Button,
  Divider,
  Tabs,
  Tab,
  CircularProgress,
  Grid
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import moment from 'moment';

import { Page } from '../../Components/Page';
import { TabPanel, a11yProps } from '../../Components/TabPanel';
import { OrdersForm } from '../../Components/Orders/OrdersForm';
import { Location2 } from '../../Components/Orders/Location2';
import { SplitClosing } from '../../Components/Orders/Fields/SplitClosing';
import { createPayload } from '../../Common/CreatePayload';

import {
  allowedFieldsWhileSaving,
  fLoanNumber,
  locationOne,
  locationTwo,
  validationSchemaWithoutSplit,
  validationSchemaWithSplit
} from '../../Common/Rules/OrdersForm';

import {
  forceCreateOrder,
  feeDetails,
  refetchOrders
} from '../../Recoil/Atoms/Orders';
import {
  createOrder,
  updateOrder,
  getOrderById
} from '../../Recoil/Selectors/Orders';

import { useStyles } from '../Styles/Page';
import { marginStyles } from '../../Common/Styles/Margins';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { PageLoad } from 'src/Components/LoadingScreen/PageLoad';
import { selectedClient } from 'src/Recoil/Atoms/Clients';
import { selectedDivision } from 'src/Recoil/Atoms/Divisions';

export const NewOrder = (props: any) => {
  const classes = useStyles();
  const marginClasses = marginStyles();
  const [split, handleSplit] = useState(false);
  const [pane, handlePane] = useState(false);
  const [id, setId] = useState(false);

  const [tab, handleTab] = useState(0);
  const [formTouchedOrSubmitted, setFormTouchedOrSubmitted] = useState(false);
  const [paneTitle, handleTitle] = useState('');
  const forceUpdates: any = useSetRecoilState(forceCreateOrder);
  const fees = useRecoilValue(feeDetails);
  let { id: currentId, order_id }: any = useParams();

  useEffect(() => {
    setId(currentId);
    if (props?.id) {
      setId(props?.id);
    }
  }, [currentId, props]);

  const { justView, open = true } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const isEditMode = Boolean(id);
  const [isViewOnlyMode, setViewModeOnly] = useState(
    location?.pathname?.includes('/orders/view/') && id
      ? true
      : justView
        ? true
        : false
  );
  const [isDuplicate] = useState(
    location?.pathname?.includes('/orders/duplicate/') ? true : false
  );
  const duplicate = Boolean(order_id);
  const setRefetchOrders = useSetRecoilState(refetchOrders);
  const [order, setOrder] = useState({
    w_Loan_Number: '',
    clientID: '',
    division_id: '',
    fees: [],
    signer: undefined,
    w_outsource: '',
    w_Appointment_Time: '',
    additionalBorrowersModel: []
    // Include other default properties as needed
  });
  const [initialFormValues, setInitialFormValues] = useState<any>(null);
  const [reset, setReset] = useState(false);
  const [isLoadingOrder, setLoadingOrder] = useState(false);
  const feesRef = useRef(null);

  const setClient = useSetRecoilState(selectedClient);
  const setDivision = useSetRecoilState(selectedDivision);

  const getOrderDetails = useRecoilCallback(
    ({ snapshot }) =>
      async ({ stopLoading }) => {
        const res: any = await snapshot.getPromise(
          getOrderById({ id: id || order_id })
        );

        if (res.success) {
          const orderData = {
            ...res.result,
            w_Appointment_Time: moment(
              res?.result?.w_Appointment_Time,
              'hh:mm A'
            ),
            additionalBorrowersModel: res?.result?.additionalBorrowersModel
              ? res.result.additionalBorrowersModel
              : []
          };
          setOrder(orderData);

          // Set initialFormValues after fetching order data
          setInitialFormValues({
            w_Loan_Number:
              isEditMode || duplicate ? [orderData.w_Loan_Number] : fLoanNumber,
            location_one: isEditMode
              ? { ...orderData }
              : duplicate
                ? {
                    ...orderData,
                    w_Appointment_Date: '',
                    w_Appointment_Time_type: 'SC',
                    w_Appointment_Time: '',
                    w_outsource: orderData?.w_outsource || 'N',
                    f_status_web: 'In Process'
                  }
                : locationOne,
            location_two: locationTwo
          });

          setViewModeOnly(
            isDuplicate
              ? false
              : res?.result?.order_locked === 'Y'
                ? true
                : isViewOnlyMode
          );
          setClient(res?.result?.clientID);
          setDivision(res?.result?.division_id);
          stopLoading();
        } else {
          enqueueSnackbar(
            res?.result?.message || 'Failed to load order details',
            {
              variant: 'error'
            }
          );
        }
      }
  );

  useEffect(() => {
    if ((isEditMode || duplicate) && open) {
      setLoadingOrder(true);
      getOrderDetails({
        stopLoading: () => {
          setLoadingOrder(false);
        }
      });
    } else if (!isEditMode && !duplicate && open) {
      // Set initialFormValues for new orders
      setInitialFormValues({
        w_Loan_Number: fLoanNumber,
        location_one: locationOne,
        location_two: locationTwo
      });
    }
  }, [id, duplicate, isEditMode, open]);

  const onSubmit = useRecoilCallback(
    ({ snapshot }) =>
      async (values: any, { setSubmitting, resetForm }) => {
        forceUpdates((n) => n + 1);
        setFormTouchedOrSubmitted(true);
        if (!Array.isArray(fees?.fees) || !fees?.fees.length) {
          feesRef?.current?.focus();
          enqueueSnackbar('At least one fee is required', {
            variant: 'error'
          });
          setSubmitting(false);
          return;
        }
        let allowedValues = {
          location_one: {},
          location_two: {},
          w_Loan_Number: values.w_Loan_Number
        };
        if (!isEditMode) {
          Object.keys(values.location_one).forEach((key) => {
            if (allowedFieldsWhileSaving.indexOf(key) > -1)
              allowedValues.location_one[key] = values.location_one[key];
          });
          if (split) {
            Object.keys(values.location_two).forEach((key) => {
              if (allowedFieldsWhileSaving.indexOf(key) > -1)
                allowedValues.location_two[key] = values.location_two[key];
            });
          }
        } else {
          allowedValues = Object.assign({}, values);
          delete allowedValues.location_two;
        }

        const params = await createPayload(allowedValues);

        if (!split) {
          delete params.location_two;
        } else {
          params.location_two.division_id = params.location_one.division_id;
          params.location_two.clientID = params.location_one.clientID;
          params.location_two.Contact_ID = params.location_one.Contact_ID;
          params.location_two.additional_client_email =
            params.location_one.additional_client_email;
        }
        const action = isEditMode ? updateOrder : createOrder;

        const res: any = await snapshot.getPromise(
          action({ ...params, fees: fees.fees })
        );
        setSubmitting(true);
        if (res.success) {
          if (!isEditMode) {
            setReset(true);
            resetForm();
            setRefetchOrders((n) => ++n);
            // Reset initialFormValues to default after submission
            setInitialFormValues({
              w_Loan_Number: fLoanNumber,
              location_one: locationOne,
              location_two: locationTwo
            });
          } if ((isEditMode || duplicate)) {
            setLoadingOrder(true);
            getOrderDetails({
              stopLoading: () => {
                setLoadingOrder(false);
              }
            });
          }
          enqueueSnackbar(
            isEditMode
              ? 'Order updated successfully'
              : 'Order added successfully',
            {
              variant: 'success'
            }
          );
          if (duplicate) {
            navigate('/orders/');
          }
          setReset(false);
        } else {
          enqueueSnackbar(res?.result?.data?.message || 'Error occurred', {
            variant: 'error'
          });
        }
        setSubmitting(false);
      }
  );

  const handlePaneOpen = (title: any) => {
    handlePane(true);
    handleTitle(title);
  };

  const onInvalid = () => {
    if (formTouchedOrSubmitted) {
      enqueueSnackbar('Please fill out all the required details', {
        variant: 'error'
      });
    }
  };

  const pageTitle = isViewOnlyMode
    ? 'View Order'
    : isEditMode
      ? 'Edit Order'
      : 'New Order';

  return (
    <SnackbarProvider>
      <Page className={classes.paper} title={pageTitle}>
        {isLoadingOrder ? (
          <PageLoad />
        ) : (
          <>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <Typography variant='h3'>{pageTitle}</Typography>
              </Grid>
              <Grid item>
                {!props?.id && (
                  <Button onClick={(e) => navigate(-1)}>Back</Button>
                )}
              </Grid>
            </Grid>
            <Box my={2}>
              <Divider variant='fullWidth' />
            </Box>
            <Box className={isViewOnlyMode ? classes.viewModeClass : ''}>
              {initialFormValues ? (
                <Formik
                  enableReinitialize
                  initialValues={initialFormValues}
                  validationSchema={
                    split
                      ? validationSchemaWithSplit
                      : validationSchemaWithoutSplit
                  }
                  onSubmit={onSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue
                  }: any) => {
                    return (
                      <Form
                        onSubmit={(e) => {
                          handleSubmit(e);
                          if (
                            errors &&
                            Object.keys(errors).length &&
                            formTouchedOrSubmitted
                          ) {
                            onInvalid();
                          }
                        }}
                      >
                        {!isEditMode && (
                          <SplitClosing
                            split={split}
                            handleSplit={handleSplit}
                          />
                        )}
                        {split ? (
                          <>
                            <Tabs
                              className={marginClasses.mb3}
                              value={tab}
                              centered
                              variant='fullWidth'
                              onChange={(e, t) => handleTab(t)}
                            >
                              <Tab label='Location One' {...a11yProps(0)} />
                              <Tab
                                label='Location Two'
                                {...a11yProps(1)}
                                disabled={!split}
                              />
                            </Tabs>
                            <TabPanel tab={tab} index={0}>
                              <OrdersForm
                                feesRef={feesRef}
                                disabled={justView}
                                isViewOnlyMode={isViewOnlyMode}
                                signer={order.signer}
                                isEditMode={isEditMode}
                                location='location_one'
                                values={values}
                                reset={reset}
                                errors={errors}
                                touched={touched}
                                isSubmitting={isSubmitting}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                handleSubmit={handleSubmit}
                                setFieldValue={setFieldValue}
                                pane={pane}
                                paneTitle={paneTitle}
                                handlePane={handlePane}
                                handlePaneOpen={handlePaneOpen}
                                orderFees={
                                  order.fees.length > 0 ? order.fees : null
                                }
                              />
                            </TabPanel>
                            <TabPanel tab={tab} index={1}>
                              <Location2
                                feesRef={feesRef}
                                isViewOnlyMode={isViewOnlyMode}
                                signer={order.signer}
                                isEditMode={isEditMode}
                                location='location_two'
                                values={values}
                                errors={errors}
                                reset={reset}
                                touched={touched}
                                isSubmitting={isSubmitting}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                handleSubmit={handleSubmit}
                                setFieldValue={setFieldValue}
                                pane={pane}
                                paneTitle={paneTitle}
                                handlePane={handlePane}
                                handlePaneOpen={handlePaneOpen}
                              />
                            </TabPanel>
                          </>
                        ) : (
                          <OrdersForm
                            feesRef={feesRef}
                            isViewOnlyMode={isViewOnlyMode}
                            signer={order.signer}
                            isEditMode={isEditMode}
                            location='location_one'
                            values={values}
                            reset={reset}
                            errors={errors}
                            touched={touched}
                            isSubmitting={isSubmitting}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            handleSubmit={handleSubmit}
                            setFieldValue={setFieldValue}
                            pane={pane}
                            paneTitle={paneTitle}
                            handlePane={handlePane}
                            handlePaneOpen={handlePaneOpen}
                            orderFees={
                              order?.fees?.length > 0 ? order.fees : null
                            }
                          />
                        )}
                        {!isViewOnlyMode && (
                          <>
                            <Box
                              mt={3}
                              display='flex'
                              justifyContent='flex-end'
                            >
                              {Object.keys(errors).length > 0 &&
                                formTouchedOrSubmitted}
                            </Box>
                            <Box
                              mt={3}
                              display='flex'
                              justifyContent='flex-end'
                            >
                              <Button
                                variant='contained'
                                size='large'
                                color='primary'
                                type='submit'
                                disabled={isSubmitting}
                              >
                                {isSubmitting && (
                                  <CircularProgress
                                    className={marginClasses.mr2}
                                    color='inherit'
                                    size={25}
                                  />
                                )}
                                Submit
                              </Button>
                            </Box>
                          </>
                        )}
                      </Form>
                    );
                  }}
                </Formik>
              ) : (
                <CircularProgress />
              )}
            </Box>
          </>
        )}
      </Page>
    </SnackbarProvider>
  );
};
