import * as Yup from 'yup';
const phoneType = [
  { name: 'iPhone', value: 'IP' },
  { name: 'Android', value: 'A' },
  { name: 'Blackberry', value: 'B' },
  { name: 'Microsoft', value: 'M' },
  { name: 'Other phone with web browsers', value: 'OPWWB' },
  { name: 'Phone with no internet', value: 'PWNI' },
  { name: 'Other', value: 'O' },
];

const languages = [
  'English',
  'Spanish',
  'Chinese',
  'Japanese',
  'Vietnamese',
  'Farsi',
  'Armenian',
  'Russian',
  'French',
  'Hebrew',
  'Italian',
  'Tagalog',
  'Indian',
  'Korean',
  'German',
  'Romanian',
  'Portuguese',
  'Hatian-Creloe',
  'Mandarin',
  'Taiwanese',
  'Punjabi',
  'Arabic',
  'Sign Language',
  'Other'
];
const SignerTypes = ['Notary', 'Attorney'];
const SignerStatuses = ['Active', 'Inactive'];

const initialValues = {
  latitude: '',
  longitude: '',
  nsa_member_number: '',
  signer_type: '',
  company_name: '',
  Signer_Email: '',
  Signer_Account_Type: '',
  home_phone: '',
  mobile_phone: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  payment_address: '',
  payment_city: '',
  payment_state: '',
  payment_zip: '',
  Signer_First: '',
  Signer_Last: '',
  Signer_Status: 'Active',
  operation_notes: '',
  certified_signing_agent: '',
  fidelity_approved: '',
  other_language: ['English'],
  commisioning_states: [],
  pmntAdrsSameAsAdrs: false,
  emergency_first_name: '',
  emergency_last_name: '',
  emergency_phone_no: '',
  phone_type: '',
  set_loan_document: '',
  ConfirmationOptions: {
    email: false,
    textMessage: false,
    phone: false
  },
  send_receive_sms: '',
  PreferredNotary: '',
  NNACertifiedNotary: '',
  prfCom: [],
  signer_preffered_ind: ''
};

const phoneRegExp = /^(\([0-9]{3}\) ?|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/

const validationSchema = Yup.object().shape({
  signer_type: Yup.string().required('Signer Type is Required.'),
  Signer_First: Yup.string().required('First Name is Required.'),
  Signer_Last: Yup.string().required('Client Email Address is Required.'),
  Signer_Status: Yup.string().required('Signer Status is Required.'),
  Signer_Account_Type: Yup.string().required('Account Type is Required.'),
  Signer_Email: Yup.string()
    .email('Signer Email Address is Invalid.')
    .required('Signer Email Address is Required.'),
  confirm_password: Yup.string().oneOf(
    [Yup.ref('Password'), null],
    'Passwords must match'
  ),
  commisioning_states: Yup.array(),
  mobile_phone: Yup.string().required('Mobile Phone Number is Required.')
    .matches(phoneRegExp, 'Phone number must be 10 digits'),
  address: Yup.string().required('Signer Address is Required.'),
  city: Yup.string().required('Signer City is Required.'),
  state: Yup.string().required('Signer State is Required.'),
  zip: Yup.string().required('Signer Zip is Required.'),
  payment_address: Yup.string().required('Address is Required.'),
  payment_city: Yup.string().required('City is Required.'),
  payment_state: Yup.string().required('State is Required.'),
  payment_zip: Yup.string().required('Zip is Required.'),
  phone_type: Yup.string().required('Phone type is Required.'),
  certified_signing_agent: Yup.string().required('Field is Required.'),
  fidelity_approved: Yup.string().required('Field is Required.'),
  send_receive_sms: Yup.string().required('Please Select Whether or Not To Receive Text Message'),
  other_language: Yup.array()
    .of(Yup.string())
    .required('Language is Required'),

});

export {
  initialValues,
  phoneType,
  languages,
  validationSchema,
  SignerTypes,
  SignerStatuses
};
