import React from 'react';
import { useSetRecoilState } from 'recoil';
import { storage } from '../../Services/storage';
import { Grid, Box, Button } from '@material-ui/core';
import clsx from 'clsx';

import { OutlinedSection } from '../Section';
import { SlidingPane } from '../SlidingPane';

import { ClientSelection } from './Fields/ClientSelection';
import { DivisionSelection } from './Fields/DivisionSelection';
import { ContactSelection } from './Fields/ContactSelection';
import { AdditionalClientEmail } from './Fields/AdditionalClientEmail';

import { BorrowerName } from './Fields/BorrowerName';
import { CoBorrowerName } from './Fields/CoBorrowerName';
import { AdditionalBorrowers } from './Fields/AdditionalBorrowers';
import { SignerType } from './Fields/SignerType';
import { PartialSigning } from './Fields/PartialSigning';
import { BorrowerEmail } from './Fields/BorrowerEmail';
import { Outsource } from './Fields/Outsource'
import { ChangeOrderStatus } from './Fields/ChangeOrderStatus'

import { LoanNumber } from './Fields/LoanNumber';
import { AppointmentDateTime } from './Fields/AppointmentDateTime';
import { SignerAddress } from './Fields/SignerAddress';
import { PropertyLocation } from './Fields/PropertyLocation';
import { SignerPhone } from './Fields/SignerPhone';

import { SpecialInstruction } from './Fields/SpecialInstruction';
import { DocumentOptions } from './Fields/DocumentOptions';
import { ClosingType } from './Fields/ClosingType';
import { ScanBacks } from './Fields/ScanBacks';

import { OrderInformation } from './FeeAndInstructions/OrderInformation';
import { Fees } from './FeeAndInstructions/Fee';
import { SignerInfo } from './FeeAndInstructions/SignerInfo';
import { TrackingInfo } from './FeeAndInstructions/TrackingInfo';
import { InvoiceInfo } from './FeeAndInstructions/InvoiceInfo';
import { ClientConfirmation } from './FeeAndInstructions/ClientConfirmation';

import { AuditTrail } from './FeeAndInstructions/AuditTrail';
import { Notes } from './FeeAndInstructions/Notes';
import { Errors } from './FeeAndInstructions/Errors';
import { BillingInfo } from './BillingInfo'

import { notesParams } from '../../Recoil/Atoms/Orders';

import { useStyles } from './Styles/Fields';
import { marginStyles } from '../../Common/Styles/Margins';
import { displayStyles } from '../../Common/Styles/Display';


const OrdersForm = (props) => {

  const classes = useStyles();
  const marginClasses = marginStyles();
  const displayClasses = displayStyles();
  const role = storage.get('userRole') || 'unknown'

  const {
    values,
    errors,
    reset,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    pane,
    paneTitle,
    handlePane,
    handlePaneOpen,
    isEditMode,
    orderFees,
    isViewOnlyMode,
    feesRef
  } = props;

  console.log(values,"values")

  const setNotesParams = useSetRecoilState(notesParams);

  const onNotesOpen = (e) => {
    handlePaneOpen('Notes')
    if (isEditMode) {
      setNotesParams({
        Id: values?.location_one?.Id
      })
    }
  }

  const location = 'location_one';

  return (
    // <></>
    <Grid className={classes.gridRoot} container spacing={2}>
      <Grid item xs={12}>
        <Box display='flex' justifyContent='flex-end'>
          <Button
            disabled={!isEditMode}
            color='primary'
            onClick={() => handlePaneOpen('Audit Trail')}
          >
            Audit Trail
          </Button>
          <Button
            disabled={!isEditMode}
            className={marginClasses.ml2}
            color='primary'
            onClick={onNotesOpen}
          >
            View Notes
          </Button>
        </Box>
      </Grid>
      <Grid className={clsx(displayClasses.flexColumn)} item xs={12} md={6}>
        {isEditMode && (
          <OutlinedSection title='Order Information'>
            <Grid container spacing={2} alignItems='center'>
              <OrderInformation values={values[location]} />
            </Grid>
          </OutlinedSection>
        )}
        <OutlinedSection title='Client Information'>
          <Grid container item spacing={3}>
            <ClientSelection
              isViewOnlyMode={isViewOnlyMode}
              values={values[location]}
              location={location}
              handleChange={handleChange}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
            />
            <DivisionSelection
              isViewOnlyMode={isViewOnlyMode}
              values={values[location]}
              location={location}
              handleChange={handleChange}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
            />

            <ContactSelection
              isViewOnlyMode={isViewOnlyMode}
              values={values[location]}
              location={location}
              handleChange={handleChange}
              touched={touched}
              errors={errors}
            />
            <AdditionalClientEmail
              // isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              isEditMode={isEditMode}
            />
            {isEditMode && (<ClientConfirmation isViewOnlyMode={isViewOnlyMode} values={values[location]} />)}
          </Grid>
        </OutlinedSection>
        <OutlinedSection title='Signing Information'>
          <Grid container spacing={2}>
            <SignerType
              isViewOnlyMode={isViewOnlyMode}
              setFieldValue={setFieldValue}
              location={location}
              values={values[location]}
            />
            <PartialSigning
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              errors={errors}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              loanNumber={values.w_Loan_Number}
            />
            <LoanNumber
              isViewOnlyMode={isViewOnlyMode}
              values={reset ? '' : values.w_Loan_Number}
              partialSigning={values[location].Partial_Signing}
              errors={errors}
              reset={reset}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              isEditMode={isEditMode}
            />
            <AppointmentDateTime
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
            <SignerAddress
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
            <SignerPhone
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <PropertyLocation
              isViewOnlyMode={isViewOnlyMode}
              isEditMode={isEditMode}
              location={location}
              values={values[location]}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
            <SpecialInstruction
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              handleChange={handleChange}
            />
            <ClosingType
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              handleChange={handleChange}
              touched={touched}
              errors={errors}
            />
            <ScanBacks
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              handleChange={handleChange}
            />
          </Grid>
        </OutlinedSection>
        {isEditMode && (
          <OutlinedSection title='Signer Information'>
            <Grid container spacing={2} alignItems='center'>
              <SignerInfo
                isViewOnlyMode={isViewOnlyMode}
                handlePaneOpen={handlePaneOpen}
                values={values[location]}
              />
            </Grid>
          </OutlinedSection>)}
      </Grid>
      <Grid className={clsx(displayClasses.flexColumn)} item xs={12} md={6}>
        <OutlinedSection title='Borrower Information'>
          <Grid container spacing={2} >

            <BorrowerName
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <BorrowerEmail
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <CoBorrowerName
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              handleChange={handleChange}
            />

            <AdditionalBorrowers
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          </Grid>
        </OutlinedSection>
        {isEditMode && (
          <OutlinedSection title='Documents'>
            <Grid container spacing={2} alignItems='center'>
              <DocumentOptions isViewOnlyMode={isViewOnlyMode} signer={props.signer} values={values[location]} />
            </Grid>
          </OutlinedSection>
        )}
        <OutlinedSection title='Fee Information'>
          <Grid container spacing={2} alignItems='center'>
            <Fees
              feesRef={feesRef}
              isViewOnlyMode={isViewOnlyMode}
              location={location} values={values}
              orderFees={orderFees ? orderFees : null}
            />
          </Grid>
          {(role === 'Manager' || role === 'Accountant') &&
            <Outsource
              isViewOnlyMode={isViewOnlyMode}
              location={location}
              values={values[location]}
              handleChange={handleChange}
            />
          }
          {isEditMode && (
            <ChangeOrderStatus isViewOnlyMode={isViewOnlyMode} location={location} values={values[location]} handleChange={handleChange} />
          )}
        </OutlinedSection>

        {isEditMode && (
          <>
            <OutlinedSection title='Tracking Information'>
              <Grid container spacing={2} alignItems='center'>
                <TrackingInfo
                  isViewOnlyMode={isViewOnlyMode}
                  location={location}
                  values={values[location]}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur} />
              </Grid>
            </OutlinedSection>
            <OutlinedSection title='Accounting'>
              <Grid container spacing={2} alignItems='center'>
                <InvoiceInfo
                  location={location}
                  isViewOnlyMode={isViewOnlyMode}
                  values={values[location]}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
              </Grid>
            </OutlinedSection>


            {isEditMode &&
              (
                <OutlinedSection title='Billing Information'>
                  <Grid container spacing={2} alignItems='center'>
                    <BillingInfo
                      values={values[location]}
                      handleChange={handleChange}
                      location={location}
                    />
                  </Grid>
                </OutlinedSection>)
            }
          </>
        )}
      </Grid>
      <SlidingPane
        title={paneTitle}
        open={pane}
        handlePane={handlePane}
      >
        <>
          {paneTitle === 'Audit Trail' && <AuditTrail values={values} />}
          {paneTitle === 'Notes' && <Notes isViewOnlyMode={isViewOnlyMode} />}
          {paneTitle === 'Signer Errors' && <Errors />}
        </>
      </SlidingPane>
    </Grid>
  );
}

export { OrdersForm };
