import React, { useState, useEffect } from 'react';
import {
  useRecoilCallback,
  useSetRecoilState,
  useRecoilState,
  useRecoilValue
} from 'recoil';
import {
  Grid,
  Typography,
  Select,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Divider,
  Checkbox,
  CircularProgress,
  DialogContentText
} from '@material-ui/core';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import moment from 'moment-timezone';

import { GeneralModal } from '../Modal';
import { Table } from '../Table';
import { EditSigner } from './../../Pages/Signers/EditSigner';

import Map from '../Maps/Map';
import { FileUploadModal } from '../FileUpload/FileUploadModal';

import { assignSigner, sendCancelResult } from 'src/Recoil/Selectors/Orders';
import {
  currentOrder,
  forceReassign,
  forceSendResult
} from '../../Recoil/Atoms/Orders';
import { refetchSignerOrder } from '../../Recoil/Atoms/Signers';

import { useStyles } from './Styles/assignSigner';
import { userInfo } from 'src/Recoil/Atoms/Login';

import {
  completedSigningsId,
  signerHistoryPagination
} from 'src/Recoil/Atoms/Signers';
import { SignerHistory } from './../Signers/SignerHistory';
import { SlidingPane } from './../SlidingPane';

import { NewOrder } from './../../Pages/Orders/AddEditViewOrder';

const CallSigner = (props: any) => {
  const setHistory = useSetRecoilState(signerHistoryPagination);
  const completedSigning = useSetRecoilState(completedSigningsId);
  const [historySigner, setSignerHistory] = useState(null);
  const [edit, setEdit] = useState(null);
  const [drivingTime, setDrivingTime]: any = useState({});
  const updateResult = useSetRecoilState(forceSendResult);
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [paneOpen, setPaneOpen] = useState(false);

  const handlePaneOpen = (orderId) => {
    setCurrentOrderId(orderId);
    setPaneOpen(true);
  };

  const handlePaneClose = () => {
    setPaneOpen(false);
    setCurrentOrderId(null); // Reset current order ID when closing the pane
  };

  const matchActivityColumns = [
    {
      field: 'employee',
      sortable: false,
      headerName: 'User Name',
      flex: 1,
      renderCell: ({ row }) => (
        <div>
          {row?.employee ? (
            <>
              {row?.employee?.firstName}&nbsp;{row?.employee?.lastName}
            </>
          ) : null}
        </div>
      )
    },
    {
      field: 'created_on',
      sortable: false,
      headerName: 'Time',
      flex: 1,
      renderCell: ({ row }) => (
        <div>{moment(row?.created_on).format('MM/DD/YYYY HH:mm')}</div>
      )
    },
    {
      field: 'result',
      sortable: false,
      headerName: 'Result',
      flex: 1,
      renderCell: ({ row }) => (
        <Typography>
          {resultOptions.filter((result) => result.value === row?.result)[0]
            ?.label || ''}
        </Typography>
      )
    },
    {
      field: 'comment',
      sortable: false,
      headerName: 'Comments',
      flex: 1
    }
  ];
  const classes = useStyles();
  const {
    orderDetails,
    selectedSigner,
    OpenAssignModal,
    isAssignModalOpen,
    setSigner,
    defaultValues
  } = props;

  const logs = orderDetails?.MatchedActivity?.filter(
    (log) => log.signer_id === selectedSigner.signer_id
  );
  const user = useRecoilValue(userInfo);
  const [requestedFee, setRequestedFee] = useState('');
  const [loader, setLoader] = useState(false);

  const resultOptions = [
    { value: 'NA', label: 'No Answer/Voice mail' },
    { value: 'NAV', label: 'Not Available' },
    { value: 'NAN', label: 'Not a Notary Anymore' },
    { value: 'TE', label: 'Fee Too High' },
    { value: 'OPTN', label: 'Option' },
    { value: 'OTH', label: 'Other' }
  ];

  const [options, setOptions] = useState({
    fee: true,
    date: true
  });

  const [comment, setComment] = useState('');
  const [openNANConfirmation, setNANConfirmation] = useState(false);
  const [requestedDate, setRequestedDate] = useState('');
  const setReassign = useSetRecoilState(forceReassign);

  const [result, setResult] = useState('');
  const [isUploadDocsModalOpen, setIsUploadDocsModalOpen] = useState(false);
  const [order, setCurrentOrder] = useRecoilState(currentOrder);
  const [updatedFees, setFees] = useState([]);

  const callRefresh = useSetRecoilState(refetchSignerOrder);

  const setFieldValue = (fn, val) => {
    fn(val);
  };
  useEffect(() => {
    if (orderDetails.fees?.length) {
      setFees(orderDetails?.fees || []);
    }
  }, [orderDetails]);

  const onAssignSigner = useRecoilCallback(({ snapshot }) => async (id) => {
    setLoader(true);
    setReassign((n) => ++n);
    const response = await snapshot.getPromise(
      assignSigner({
        f_signer_id: selectedSigner.signer_id,
        comment,
        result,
        fees: updatedFees,
        Id: selectedSigner.Id // Id of order
      })
    );
    if (response.success) {
      callRefresh((n) => n + 1);
      setSigner({ ...defaultValues });
      setLoader(false);
      setRequestedFee('');
      setRequestedDate('');
      setResult('');
      setComment('');
      enqueueSnackbar(
        response.result.message || 'Order assigned successfully',
        {
          variant: 'success'
        }
      );
      setDrivingTime({});
      OpenAssignModal(false);
    } else {
      setLoader(false);
      enqueueSnackbar(
        response?.result?.data?.message || 'Error occurred while assigning',
        {
          variant: 'error'
        }
      );
    }
  });

  const onCancelAssignProcess = useRecoilCallback(
    ({ snapshot }) =>
      async (id) => {
        if (!result) {
          enqueueSnackbar('Please select result', {
            variant: 'error'
          });
          return;
        }

        if (result === 'OPTN' && options.date && !requestedDate) {
          enqueueSnackbar('Please select appointment date & time', {
            variant: 'error'
          });
          return;
        }

        if (result === 'OPTN' && options.fee && !requestedFee) {
          enqueueSnackbar('Please enter fee amount', {
            variant: 'error'
          });
          return;
        }

        if (result === 'OTH' && !comment) {
          enqueueSnackbar('Please enter comment', {
            variant: 'error'
          });
          return;
        }
        setNANConfirmation(false);
        setLoader(true);

        const response = await snapshot.getPromise(
          sendCancelResult({
            signer_id: selectedSigner.signer_id,
            comment,
            result,
            borrower_id: selectedSigner.Id, // Id of order
            offering_fee: selectedSigner.f_signer_fees || '',
            createdBy: user?.id,
            ...(result === 'OPTN'
              ? { option_datetime: requestedDate, option_fee: requestedFee }
              : { requested_fee: requestedFee })
          })
        );

        updateResult((n) => ++n);
        if (response.success) {
          callRefresh((n) => n + 1);
          setSigner({ ...defaultValues });
          setLoader(false);
          setRequestedFee('');
          setRequestedDate('');
          setResult('');
          setComment('');
          // props.enqueueSnackbar(
          //   response.result.data || 'Activity Added',
          //   {
          //     variant: 'success'
          //   }
          // )
          setDrivingTime({});
          OpenAssignModal(false);
        } else {
          setLoader(false);
          enqueueSnackbar(
            response.result.message || 'Error occurred while sending result',
            {
              variant: 'error'
            }
          );
        }
      }
  );
  const feeField =
    selectedSigner.signer_type === 'Notary' ? 'fee_amount' : 'attorney_fee';
  const billField =
    selectedSigner.signer_type === 'Notary' ? 'bill_amount' : 'attorney_bill';
  const updateNotaryFee = (id, value) => {
    const updatedNotaryFee = updatedFees.map((fee) => {
      return { ...fee, [feeField]: id === fee.id ? value : fee.f_Fee_Amount };
    });
    setFees(updatedNotaryFee);
  };
  const signerFieldsToShow = [
    {
      label: 'Loan/Order#',
      field: 'f_loan_number',
      render: (
        <Button
          onClick={() => {
            handlePaneOpen(selectedSigner?.Id);
          }}
        >
          {selectedSigner.f_loan_number} <br></br>{' '}
        </Button>
      )
    },
    {
      label: 'Documents',
      field: 'f_loan_number',
      gridSm: true,
      render: (
        <>
          <a
            href='/'
            onClick={(e) => {
              e.preventDefault();
              setCurrentOrder({ ...order, ...selectedSigner });
              setIsUploadDocsModalOpen(true);
            }}
          >
            {props?.orderDetails?.documentCount || 0} Documents <br></br>Upload
            More
          </a>
        </>
      )
    },
    {
      label: 'Closing Type',
      field: 'Closing_Type',
      gridSm: true,
      render: (
        <>
          {selectedSigner.Closing_Type} <br></br>{' '}
        </>
      )
    },
    {
      label: 'Appointment Date/Time',
      field: 'w_Appointment_Time',
      render: (
        <>
          {selectedSigner.w_Appointment_Date &&
            new Date(
              selectedSigner.w_Appointment_Date
            ).toLocaleDateString()}{' '}
          {selectedSigner.w_Appointment_Time_type === 'OT'
            ? 'Open Time'
            : moment(selectedSigner.w_Appointment_Time, 'hh:mm A').format(
                'hh:mm A'
              )}
        </>
      )
    },
    { label: 'Zip Code', field: 'w_Signing_Zip' },
    {
      label: 'Signer Id',
      field: 'signer_id',
      render: (
        <Button onClick={() => setEdit(selectedSigner?.signer_id)}>
          {selectedSigner?.signer_id}
        </Button>
      )
    },
    { label: 'Address', field: 'address' },
    {
      label: 'Name',
      field: 'Signer_First',
      render: (
        <>
          {`${selectedSigner.Signer_First}
        ${selectedSigner.Signer_Last}`}
        </>
      )
    },
    { label: 'City', field: 'city' },
    {
      label: 'Email',
      field: 'Signer_Email',
      render: (
        <>
          <a href={`mailto:${selectedSigner.Signer_Email}`}>
            {selectedSigner.Signer_Email}
          </a>
        </>
      )
    },
    { label: 'State', field: 'state' },
    { label: 'Zip', field: 'zip' },
    { label: 'Mobile Phone', field: 'mobile_phone' },
    {
      label: 'Driving Distance',
      field: 'distance',
      render: <>{drivingTime?.distance?.text || '0 miles'}</>
    },
    { label: 'Home Phone', field: 'home_phone' },
    {
      label: 'Driving Time',
      field: 'drivingTime',
      render: (
        <Typography> {drivingTime?.duration?.text || '0 mins'} </Typography>
      )
    },
    {
      label: '#Signings',
      field: 'signing',
      render: (
        <Button
          onClick={() => {
            setHistory({
              signer_id: selectedSigner.signer_id,
              limit: 20,
              page: 1,
              count: 0,
              orderBy: '',
              orderType: ''
            });
            completedSigning(selectedSigner?.signer_id);
            setSignerHistory(selectedSigner?.signer_id);
          }}
        >
          {selectedSigner?.signing}
        </Button>
      )
    },
    { label: '#errors', field: 'errors' },
    { label: 'Signer Notes', field: 'operation_notes' }
  ];

  const FeesColumns = [
    {
      field: 'fee_name',
      headerName: 'Service Type',
      disableColumnMenu: true
    },
    {
      field: feeField,
      headerName: 'Notary Fee',
      disableColumnMenu: true,
      renderCell: (rowDetails) => (
        <TextField
          value={rowDetails?.row?.[feeField]}
          onChange={(e) => updateNotaryFee(rowDetails?.row?.id, e.target.value)}
          type='number'
          variant='outlined'
        ></TextField>
      )
    },
    {
      field: billField,
      headerName: 'Client Notary Fee',
      disableColumnMenu: true
    }
  ];

  return (
    <SnackbarProvider>
      <GeneralModal
        open={isAssignModalOpen}
        openModal={
          result === 'NAN'
            ? () => setNANConfirmation(true)
            : onCancelAssignProcess
        }
        title='Call Signer'
        cancelTitle='Save Result'
        noSubmit
        loading={loader}
      >
        <GeneralModal
          open={openNANConfirmation}
          openModal={setNANConfirmation}
          title='Call Signer'
          handleSubmit={onCancelAssignProcess}
          submitLabel='Ok'
          loading={loader}
        >
          <DialogContentText>
            Are you sure you want to make this notary inactive? This will
            disable the notary from showing up in the system for other orders.
          </DialogContentText>
        </GeneralModal>

        <GeneralModal
          title='Signer History'
          open={historySigner}
          openModal={(e) => setSignerHistory(false)}
          noSubmit
        >
          <SignerHistory id={historySigner} />
        </GeneralModal>

        <GeneralModal
          title='Edit Signer'
          open={edit}
          openModal={() => setEdit(null)}
          noSubmit
          maxWidth='xl'
        >
          {edit && <EditSigner id={edit} />}
        </GeneralModal>
        {selectedSigner ? (
          <>
            <Grid container spacing={4}>
              {isUploadDocsModalOpen && (
                <FileUploadModal
                  open={isUploadDocsModalOpen}
                  handleFileModal={setIsUploadDocsModalOpen}
                />
              )}
              {signerFieldsToShow.map((f) => (
                <Grid
                  item
                  xs={f.gridSm ? 3 : 6}
                  container
                  justifyContent='space-between'
                  key={f.field}
                >
                  <Typography variant='h5'>{f.label}</Typography>
                  <Typography variant='subtitle1'>
                    {f.render || selectedSigner[f.field]}
                  </Typography>
                  <Grid item xs={12}>
                    <Divider></Divider>
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Typography variant='h4'>Fees</Typography>
                <Table
                  uniqueKey='id'
                  pagination={false}
                  columns={FeesColumns}
                  rows={updatedFees || []}
                  total={updatedFees?.length || 0}
                />
              </Grid>
              <Grid xs={12} item container>
                <Grid item xs={4}>
                  <FormControl margin='dense' variant='outlined' fullWidth>
                    <InputLabel>Result</InputLabel>
                    <Select
                      label='Result'
                      name='Result'
                      value={result}
                      fullWidth
                      onChange={e => setFieldValue(setResult, e.target.value)}
                    >
                      {resultOptions.map((r) => (
                        <MenuItem key={r.value} value={r.value}>
                          {r.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>{' '}
                </Grid>
                {result === 'TE' && (
                  <Grid item xs={4}>
                    <TextField
                      className={classes.moveBottom}
                      label='Requested Fee'
                      variant='outlined'
                      size='small'
                      type='number'
                      fullWidth
                      value={requestedFee}
                      onChange={(e) =>
                        setFieldValue(setRequestedFee, e.target.value)
                      }
                    ></TextField>
                  </Grid>
                )}
                {result === 'OPTN' && (
                  <Grid item container xs={4} spacing={4}>
                    <Grid xs={12} item container>
                      <Checkbox
                        checked={options.date}
                        onChange={(e) =>
                          setOptions({ ...options, date: e.target.checked })
                        }
                      ></Checkbox>
                      <Grid xs={10} item>
                        <TextField
                          size='small'
                          id='datetime-local'
                          label='Date time'
                          type='datetime-local'
                          defaultValue={requestedDate}
                          onChange={(e) => setRequestedDate(e.target.value)}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid xs={12} item container>
                      <Checkbox
                        checked={options.fee}
                        onChange={(e) =>
                          setOptions({ ...options, fee: e.target.checked })
                        }
                      ></Checkbox>
                      <Grid item>
                        <TextField
                          value={requestedFee}
                          onChange={(e) => setRequestedFee(e.target.value)}
                          type='number'
                          label='Fee'
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid xs={12} item container>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    rows={5}
                    label='Comment'
                    name='Comment'
                    variant='outlined'
                    size='small'
                    fullWidth
                    value={comment}
                    onChange={(e) => setFieldValue(setComment, e.target.value)}
                  ></TextField>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant='h4'>Call Logs</Typography>
                <Table
                  useCustomStyles={false}
                  pagination={false}
                  columns={matchActivityColumns}
                  rows={logs || []}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent='space-around'>
              <Grid item>
                <br></br>
                <Button
                  disabled={loader}
                  variant='contained'
                  color='primary'
                  onClick={onAssignSigner}
                >
                  {loader && (
                    <>
                      {' '}
                      <CircularProgress
                        size={24}
                        color='inherit'
                      ></CircularProgress>{' '}
                      &nbsp;
                    </>
                  )}
                  Assign
                </Button>
              </Grid>
              <Grid item xs={12}>
                <br></br>
                <a
                  rel='noopener noreferrer'
                  target='_blank'
                  href={`https://maps.google.com/maps?daddr=${orderDetails.w_Signing_Street_Address}, ${orderDetails.w_Signing_City}
              ${orderDetails.w_Signing_State} ${orderDetails.w_Signing_Zip}
              &hl=en`}
                >
                  {orderDetails.w_Signing_Street_Address},{' '}
                  {orderDetails.w_Signing_City}
                  {orderDetails.w_Signing_State} {orderDetails.w_Signing_Zip}@{' '}
                  {orderDetails.w_Appointment_Date}{' '}
                  {orderDetails.w_Appointment_Time}
                </a>
                {(selectedSigner?.latitude || selectedSigner?.address) &&
                orderDetails.w_Signing_Street_Address ? (
                  <Map
                    distance={selectedSigner.distance}
                    orderLocation={{
                      lat: orderDetails.latitude,
                      lng: orderDetails.longitude
                    }}
                    drivingTime={drivingTime}
                    setDrivingTime={setDrivingTime}
                    signerLocation={{
                      lat: selectedSigner.latitude,
                      lng: selectedSigner.longitude
                    }}
                    orderAddress={`${orderDetails.w_Signing_Street_Address}, ${orderDetails.w_Signing_City} ${orderDetails.w_Signing_State} ${orderDetails.w_Signing_Zip}`}
                    signerAddress={`${selectedSigner.address} ${selectedSigner.city} ${selectedSigner.state} ${selectedSigner.zip}`}
                  ></Map>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
            <SlidingPane
              title={'Edit Order'}
              open={paneOpen}
              handlePane={handlePaneClose}
              width='95%'
            >
              {paneOpen && (
                <NewOrder
                  key={currentOrderId || 'new'}
                  id={currentOrderId}
                  open={paneOpen}
                />
              )}
            </SlidingPane>
          </>
        ) : (
          <></>
        )}
      </GeneralModal>
    </SnackbarProvider>
  );
};

export { CallSigner };
