import React, { Fragment } from 'react';
import {
  Grid,
  TextField,
  Button,
  SvgIcon,
  FormControl,
  Typography,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { FieldArray } from 'formik';
import { Plus, XCircle } from 'react-feather';
import clsx from 'clsx';

import { displayStyles } from '../../../Common/Styles/Display';
import { marginStyles } from '../../../Common/Styles/Margins';

const Fee = ({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => {
  const displayClasses = displayStyles();
  const marginClasses = marginStyles();

  return (
    <>
      <Grid className={displayClasses.flexRowCE} item xs={12}>
        <Button
          color='primary'
          variant='outlined'
          onClick={
            () => setFieldValue(`fees[${values.fees.length}]`, {
              default: 'N',
              default_dayofmonth_start: '',
              default_dayofmonth_end: '',
              fee_name: 'Standard Signing /E-Docs',
              fee_amount: '',
              attorney_fee: '',
              bill_amount: '',
              attorney_bill: ''
            })
          }
        >
          <SvgIcon fontSize='small'><Plus /></SvgIcon> Add
        </Button>
      </Grid>
      <FieldArray
        name='fees'
        render={(helper) => (
          values.fees.map((val, i) =>
            <Fragment key={`fees-${i}`}>
              {i > 0 &&
                <Grid className={displayClasses.flexRowCE} item xs={12}>
                  <SvgIcon
                    fontSize='small'
                    color='primary'
                    onClick={() => helper.remove(i)}
                  >
                    <XCircle />
                  </SvgIcon>
                </Grid>
              }
              <Grid item md={3} xl={2} xs={6}>
                <FormControl className={clsx(marginClasses.mb0, marginClasses.mt0)} margin='dense' variant='outlined'>
                  <InputLabel>Service Type</InputLabel>
                  <Select
                    label='Service Type'
                    name={`fees[${i}].fee_name`}
                    fullWidth
                    value={val.fee_name}
                    onChange={handleChange}
                  >
                    <MenuItem value='Standard Signing /E-Docs'>Standard Signing /E-Docs</MenuItem>
                    <MenuItem value='Standard Signing/Overnight'>Standard Signing/Overnight</MenuItem>
                    <MenuItem value='Brrower No Show/Not Signed'>Brrower No Show/Not Signed</MenuItem>
                    <MenuItem value='Additional Loan'>Additional Loan</MenuItem>
                    <MenuItem value='Additional Trip'>Additional Trip</MenuItem>
                    <MenuItem value='E-Docs'>E-Docs</MenuItem>
                    <MenuItem value='Re-Print'>Re-Print</MenuItem>
                    <MenuItem value='Partial Document Signing'>Partial Document Signing</MenuItem>
                    <MenuItem value='Scan Backs'>Scan Backs</MenuItem>
                    <MenuItem value='Witness Fee'>Witness Fee</MenuItem>
                    <MenuItem value='Title/Attorney Office'>Title/Attorney Office</MenuItem>
                    <MenuItem value='Loan Modification'>Loan Modification</MenuItem>
                    <MenuItem value='Large Package'>Large Package</MenuItem>
                    <MenuItem value='HELOC'>HELOC</MenuItem>
                    <MenuItem value='Fax Back'>Fax Back</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item md={3} xl={1} xs={6}>
                <TextField
                  label='From'
                  name={`fees[${i}].default_dayofmonth_start`}
                  variant='outlined'
                  size='small'
                  fullWidth
                  value={val.default_dayofmonth_start}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    (touched.fees && touched.fees[i] && touched.fees[i].default_dayofmonth_start) &&
                    (errors.fees && errors.fees[i] && errors.fees[i].default_dayofmonth_start)
                  }
                  error={Boolean(
                    (touched.fees && touched.fees[i] && touched.fees[i].default_dayofmonth_start) &&
                    (errors.fees && errors.fees[i] && errors.fees[i].default_dayofmonth_start)
                  )}
                />
              </Grid>
              <Grid item md={3} xl={1} xs={6}>
                <TextField
                  label='To'
                  name={`fees[${i}].default_dayofmonth_end`}
                  variant='outlined'
                  size='small'
                  fullWidth
                  value={val.default_dayofmonth_end}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    (touched.fees && touched.fees[i] && touched.fees[i].default_dayofmonth_end) &&
                    (errors.fees && errors.fees[i] && errors.fees[i].default_dayofmonth_end)
                  }
                  error={Boolean(
                    (touched.fees && touched.fees[i] && touched.fees[i].default_dayofmonth_end) &&
                    (errors.fees && errors.fees[i] && errors.fees[i].default_dayofmonth_end)
                  )}
                />
              </Grid> */}
              <Grid item md={3} xl={1} xs={6}>
                <TextField
                  label='Notary Fee'
                  name={`fees[${i}].fee_amount`}
                  variant='outlined'
                  size='small'
                  fullWidth
                  value={val.fee_amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    (touched.fees && touched.fees[i] && touched.fees[i].fee_amount) &&
                    (errors.fees && errors.fees[i] && errors.fees[i].fee_amount)
                  }
                  error={Boolean(
                    (touched.fees && touched.fees[i] && touched.fees[i].fee_amount) &&
                    (errors.fees && errors.fees[i] && errors.fees[i].fee_amount)
                  )}
                />
              </Grid>

              <Grid item md={3} xl={2} xs={6}>
                <TextField
                  label='Client Notary Fee'
                  name={`fees[${i}].bill_amount`}
                  variant='outlined'
                  size='small'
                  fullWidth
                  value={val.bill_amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    (touched.fees && touched.fees[i] && touched.fees[i].bill_amount) &&
                    (errors.fees && errors.fees[i] && errors.fees[i].bill_amount)
                  }
                  error={Boolean(
                    (touched.fees && touched.fees[i] && touched.fees[i].bill_amount) &&
                    (errors.fees && errors.fees[i] && errors.fees[i].bill_amount)
                  )}
                />
              </Grid>
              <Grid item md={3} xl={2} xs={6}>
                <TextField
                  label='Attorney Fee'
                  name={`fees[${i}].attorney_fee`}
                  variant='outlined'
                  size='small'
                  fullWidth
                  value={val.attorney_fee}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    (touched.fees && touched.fees[i] && touched.fees[i].attorney_fee) &&
                    (errors.fees && errors.fees[i] && errors.fees[i].attorney_fee)
                  }
                  error={Boolean(
                    (touched.fees && touched.fees[i] && touched.fees[i].attorney_fee) &&
                    (errors.fees && errors.fees[i] && errors.fees[i].attorney_fee)
                  )}
                />
              </Grid>
              <Grid item md={3} xl={2} xs={6}>
                <TextField
                  label='Client Attorney Fee'
                  name={`fees[${i}].attorney_bill`}
                  variant='outlined'
                  size='small'
                  fullWidth
                  value={val.attorney_bill}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    (touched.fees && touched.fees[i] && touched.fees[i].attorney_bill) &&
                    (errors.fees && errors.fees[i] && errors.fees[i].attorney_bill)
                  }
                  error={Boolean(
                    (touched.fees && touched.fees[i] && touched.fees[i].attorney_bill) &&
                    (errors.fees && errors.fees[i] && errors.fees[i].attorney_bill)
                  )}
                />
              </Grid>

              <Grid item md={3} xl={1} xs={6}>
                <Typography variant='body2'>Default</Typography>
                <FormControlLabel
                  label='Yes'
                  control={
                    <Radio
                      name={`fees[${i}].default`}
                      value='Y'
                      checked={val.default === 'Y'}
                      onChange={handleChange}
                    />
                  }
                />
                <FormControlLabel
                  label='No'
                  control={
                    <Radio
                      name={`fees[${i}].default`}
                      value='N'
                      checked={val.default === 'N'}
                      onChange={handleChange}
                    />
                  }
                />
              </Grid>

            </Fragment>
          )
        )}
      />
    </>
  );
}

export { Fee };
